import React, { useState, useEffect } from "react";
import "./UserProfileForm.css";
import apiClient from "../../../../utils/axiosConfig";
import Button from "../../../Button/Button";
import { useSearchParams, useNavigate } from "react-router-dom";
import Navbar from "../../../Navbar/Navbar";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useSelector } from "react-redux";
const UserProfileForm = () => {
  const navigate = useNavigate();
  const [filterSalesLead, setFilterSalesLead] = useState([]);
  const allUsersInfoData = useSelector((state) => state.user.allUsersInfo);
  const [searchParams] = useSearchParams();
  const id = searchParams.get("userid");
  // console.log(id);

  const [formData, setFormData] = useState({
    username: "",
    password: "",
    first_name: "",
    last_name: "",
    email: "",
    role: "",
    sales_target: "",
    reporting_manager_id: "",
    registration_code: "",
    is_active: false,
    is_staff: false,
    is_superuser: false,
  });

  useEffect(() => {
    if (id) {
      fetchUserData(id);
    }
  }, [id]);

  useEffect(() => {
    FetchAllUsers();
  }, []);

  const fetchUserData = async (userId) => {
    try {
      const response = await apiClient.get(`/api/user/user/${userId}`);
      if (response.data) {
        setFormData({ ...response.data, password: "" });
      }
    } catch (error) {
      console.error("Error fetching user data:", error);
    }
  };

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  const FetchAllUsers = async () => {
    if (!allUsersInfoData.length) {
      try {
        const response = await apiClient.get("/api/user/users");
        if (response.status === 200) {
          const data = response?.data?.users;
          const filteredData = data?.filter(
            (user) => user?.role === "sales_lead"
          );
          setFilterSalesLead(filteredData);
        }
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    } else {
      const filteredData = allUsersInfoData?.filter(
        (user) => user?.role === "sales_lead"
      );
      setFilterSalesLead(filteredData);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    // console.log("Form submitted:", formData);

    try {
      if (!id) {
        const response = await apiClient.post("/api/user/newuser", {
          ...formData,
        });
        if (response.data) {
          // console.log(response.data.message);
          toast.success("User added successfully");
        }

        setTimeout(() => navigate("/sales-app"), 1500);
      } else {
        const response = await apiClient.put(`/api/user/update/${id}`, {
          ...formData,
        });
        // console.log(response.data);
        if (response.data) {
          // console.log(response.data.message);
          toast.success("User updated successfully");
        }
        setTimeout(() => navigate("/sales-app"), 1500);
      }
    } catch (error) {
      if (error.response) {
        // Server responded with a status other than 200 range
        console.error("Error from server:", error.response.data);
        console.log("Failed to save user: " + error);
      } else {
        // Other errors
        console.error("Error during fetch:", error.message);
        toast.error("Error saving user");
      }
    }
  };

  return (
    <>
      <Navbar />
      <div className="user-profile-form">
        <div style={{ marginBottom: "1rem", width: "100px" }}>
          <Button text="Back" onClick={() => window.history.back()} />
        </div>
        <h2>{id ? "Edit User" : "Add User"}</h2>

        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <label htmlFor="username">Username:</label>
            <input
              type="text"
              id="username"
              name="username"
              value={formData.username}
              onChange={handleChange}
            />
            <small>
              Required. 150 characters or fewer. Letters, digits and @/./+/-/_
              only.
            </small>
          </div>

          <div className="form-group">
            <label htmlFor="password">Password:</label>
            <input
              type="password"
              id="password"
              name="password"
              value={formData.password}
              onChange={handleChange}
            />
            <small>
              Raw passwords are not stored, so there is no way to see this
              user's password, but you can change the password using this form.
            </small>
          </div>

          <fieldset>
            <legend>Personal Info</legend>

            <div className="form-group">
              <label htmlFor="first_name">First name:</label>
              <input
                type="text"
                id="first_name"
                name="first_name"
                value={formData.first_name}
                onChange={handleChange}
              />
            </div>

            <div className="form-group">
              <label htmlFor="last_name">Last name:</label>
              <input
                type="text"
                id="last_name"
                name="last_name"
                value={formData.last_name}
                onChange={handleChange}
              />
            </div>

            <div className="form-group">
              <label htmlFor="email">Email address:</label>
              <input
                type="email"
                id="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
              />
            </div>

            <div className="form-group">
              <label htmlFor="role">Role:</label>
              <select
                id="role"
                name="role"
                value={formData.role}
                onChange={handleChange}
              >
                <option value="">Select a role</option>
                <option value="superadmin">Super Admin</option>
                <option value="salesperson">Sales Person</option>
                <option value="sales_lead">Sales Lead</option>
                <option value="operations">Operations</option>
              </select>
            </div>

            <div className="form-group">
              <label htmlFor="registration_code">Registration code:</label>
              <input
                type="text"
                id="registration_code"
                name="registration_code"
                value={formData.registration_code}
                onChange={handleChange}
              />
            </div>
          </fieldset>

          <fieldset>
            <legend>Administrative Info</legend>
            <div className="form-group">
              <label htmlFor="reporting_manager_id">Reporting Manager</label>
              <select
                name="reporting_manager_id"
                id="reporting_manager_id"
                value={formData.reporting_manager_id}
                onChange={handleChange}
              >
                <option value="">Select a Manager</option>
                {filterSalesLead.map((user) => (
                  <option key={user.id} value={user.id}>
                    {user.first_name} {user.last_name}
                  </option>
                ))}
              </select>
            </div>
            <div className="form-group">
              <label htmlFor="sales_target">Sales Target:</label>
              <input
                type="text"
                id="sales_target"
                name="sales_target"
                value={formData.sales_target}
                onChange={handleChange}
              />
            </div>
          </fieldset>

          <fieldset>
            <legend>Permissions</legend>

            <div className="form-group">
              <input
                type="checkbox"
                id="is_active"
                name="is_active"
                checked={formData.is_active}
                onChange={handleChange}
              />
              <label htmlFor="is_active">Active</label>
              <small>
                Designates whether this user should be treated as active.
                Unselect this instead of deleting accounts.
              </small>
            </div>

            <div className="form-group">
              <input
                type="checkbox"
                id="is_staff"
                name="is_staff"
                checked={formData.is_staff}
                onChange={handleChange}
              />
              <label htmlFor="is_staff">Staff status</label>
              <small>
                Designates whether the user can log into this admin site.
              </small>
            </div>

            <div className="form-group">
              <input
                type="checkbox"
                id="is_superuser"
                name="is_superuser"
                checked={formData.is_superuser}
                onChange={handleChange}
              />
              <label htmlFor="is_superuser">Superuser status</label>
              <small>
                Designates that this user has all permissions without explicitly
                assigning them.
              </small>
            </div>
          </fieldset>

          <button type="submit">Submit</button>
        </form>
        <ToastContainer position="top-right" />
      </div>
    </>
  );
};

export default UserProfileForm;
