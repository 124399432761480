import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { DateRangePicker } from "react-date-range";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import "./Table.css";
import apiClient from "../../utils/axiosConfig";
import { allUsersInfo, userSalesInfo } from "../../Redux/userSlice";
import ClipLoader from "react-spinners/ClipLoader";

const Table = () => {
  const selector = useSelector((state) => state.user.salesInfo);
  const varient = useSelector((state) => state.user.role);
  const dispatch = useDispatch();
  const role = useSelector((state) => state.user.role);
  const [userData, setUserData] = useState([]);
  const [selectedSalesPerson, setSelectedSalesPerson] = useState(null);
  const getInitialDateRange = () => {
    const storedDates = localStorage.getItem("selectedDate");
    if (storedDates) {
      const parsedDates = JSON.parse(storedDates);
      return {
        startDate: new Date(parsedDates.startDate),
        endDate: new Date(parsedDates.endDate),
        key: "selection",
      };
    }
    return {
      startDate: new Date(),
      endDate: new Date(),
      key: "selection",
    };
  };

  const [selectedDate, setSelectedDate] = useState(getInitialDateRange);

  const handleSelect = (ranges) => {
    const newSelection = ranges.selection;
    setSelectedDate(newSelection);
    localStorage.setItem(
      "selectedDate",
      JSON.stringify({
        startDate: newSelection.startDate.toISOString(),
        endDate: newSelection.endDate.toISOString(),
      })
    );
  };

  const getSalesData = async () => {
    try {
      let response;
      if (
        role === "superadmin" ||
        role === "operations" ||
        role === "salesperson"
      ) {
        response = await apiClient.get("/api/dashboard");
      } else {
        response = await apiClient.get("/api/sales-lead/sales");
      }
      if (response.data) {
        const sortedData = response.data.sales
          .slice()
          .sort(
            (a, b) => new Date(b.date_and_time) - new Date(a.date_and_time)
          );
        dispatch(userSalesInfo(sortedData));
      }
    } catch (error) {
      console.error(
        "Error fetching sales data:",
        error.response ? error.response.data : error.message
      );
    }
  };

  useEffect(() => {
    if (!selector.length) {
      getSalesData();
    }
    getUniqueRegistrationCodes();
  }, [selector.length]);

  const formatDate = (isoString) => {
    const date = new Date(isoString);
    const hours = date.getHours();
    const minutes = date.getMinutes();
    const seconds = date.getSeconds();
    const formattedHours = hours % 12 || 12;
    const ampm = hours >= 12 ? "PM" : "AM";
    return `${formattedHours}:${minutes.toString().padStart(2, "0")}:${seconds
      .toString()
      .padStart(2, "0")} ${ampm}`;
  };

  const startDate = new Date(selectedDate.startDate).setHours(0, 0, 0, 0);
  const endDate = new Date(selectedDate.endDate).setHours(23, 59, 59, 999);

  // console.log("groupedSales", groupedSales);

  const groupedSales = groupByDate(selector);
  // console.log("groupedSales", groupedSales);

  const filteredSales = Object.entries(groupedSales).reduce(
    (acc, [date, sales]) => {
      const salesDate = new Date(date).getTime();
      if (salesDate >= startDate && salesDate <= endDate) {
        acc[date] = sales;
      }
      return acc;
    },
    {}
  );
  // console.log("filterd sales data outside the useEffect", filteredSales);

  const filterSalesBySalesPerson = () => {
    if (selectedSalesPerson) {
      let salesData = [];

      Object.keys(filteredSales).forEach((date) => {
        const salesForDate = filteredSales[date]; // Get the sales array for this date

        // Step 3: Filter sales for this date by selected salesperson's registration_code
        const filteredForDate = salesForDate.filter((sale) => {
          return sale.registration_code === selectedSalesPerson;
        });

        // Step 4: Add filtered sales to salesData
        salesData = [...salesData, ...filteredForDate];
      });
      const finalData = groupByDate(salesData);
      return finalData;
    } else {
      return filteredSales;
    }
  };

  // useEffect(() => {
  //   if (!userData.length) {
  //     fetchUserData();
  //   }
  // }, []);

  const finalData = filterSalesBySalesPerson();

  const getUniqueRegistrationCodes = () => {
    const values = Object.values(finalData).flatMap((sales) => sales);
    const uniqueCodes = [];
    values.forEach((registration) => {
      if (!uniqueCodes.includes(registration.registration_code)) {
        uniqueCodes.push(registration.registration_code);
      }
    });

    setUserData(uniqueCodes);
  };

  // console.log("finalData", finalData);

  // console.log(filteredSales);

  const getSalesCount = (date) =>
    finalData[date] ? finalData[date].length : 0;

  const getTotalSalesAmount = (date) =>
    finalData[date]
      ? finalData[date].reduce(
          (acc, sale) => Number(acc) + Number(sale.amount),
          0
        )
      : 0;

  const countdata = Object.keys(finalData).map((date) => {
    return getSalesCount(date);
  });

  console.log("countdata", countdata);

  if (!selector.length) {
    return (
      <div className="loader-container">
        <ClipLoader
          color={"#4b74ff"}
          size={150}
          aria-label="Loading Spinner"
          data-testid="loader"
        />
      </div>
    );
  }

  // console.log([selectedDate, { ...selectedDate, key: "selection" }]);

  return (
    <div className="sales-container">
      <div className="sales-date-picker">
        <DateRangePicker
          ranges={[selectedDate]}
          onChange={handleSelect}
          className="responsive-date-range-picker"
        />
      </div>

      <div
        style={{
          display: "flex",
          gap: "1rem",
          alignItems: "center",
          marginBottom: "1rem",
        }}
      >
        <p>Filter By Registration Code :</p>
        <select
          name="salesPerson"
          id="salesPerson"
          onChange={(e) => setSelectedSalesPerson(e.target.value)}
        >
          <option value="" selected>
            Select Registration Code
          </option>
          {userData.map((user, i) => (
            <option key={i} value={user}>
              {user}
            </option>
          ))}
        </select>
      </div>

      {!Object.keys(finalData).length && (
        <div className="sales-container">
          <h1>No Sales Found within the selected date range</h1>
        </div>
      )}

      {Object.keys(finalData).map((date) => (
        <div key={date} className="sales-inner-container">
          <div className="salestable-header">
            <h4>Registration Count: {getSalesCount(date)}</h4>
            <h4>Enrollments on {date}</h4>
            <h4>Total Amount: {getTotalSalesAmount(date)}</h4>
          </div>

          <div className="table-responsive">
            <table className="table">
              <thead>
                <tr className="table-header">
                  <th>#</th>
                  <th>Order ID</th>
                  <th>Amount</th>
                  <th>Time</th>
                  <th>Phone Number</th>
                  <th>Customer Name</th>
                  <th>Customer Email</th>
                  {varient === "salesperson" && <th>Payment Method</th>}
                  <th>Batch</th>
                  <th>Registration Code</th>
                  <th>Internship</th>
                  <th>Approval Status</th>
                </tr>
              </thead>
              <tbody>
                {finalData[date]?.map((sale, i) => (
                  <tr key={sale.id} className="table-row">
                    <td>{i + 1}</td>
                    <td className="emp">
                      {varient === "salesperson" ? (
                        sale.order_id
                      ) : (
                        <Link to={`/sales/${sale.id}`}>{sale.order_id}</Link>
                      )}
                    </td>
                    <td className="opacity-low">{sale.amount}</td>
                    <td className="opacity-low">
                      {formatDate(sale.date_and_time)}
                    </td>
                    <td className="opacity-low">{sale.customer_phone}</td>
                    <td className="opacity-low">{sale.customer_name}</td>
                    <td className="opacity-low">{sale.customer_email}</td>
                    {varient === "salesperson" && (
                      <td className="opacity-low">{sale.payment_method}</td>
                    )}
                    <td className="opacity-low">{sale.batch}</td>
                    <td className="opacity-low">{sale.registration_code}</td>
                    <td className="opacity-low">{sale.internship}</td>
                    <td className={`opacity-low ${sale?.approval_status}`}>
                      {sale.approval_status}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      ))}
    </div>
  );
};

const groupByDate = (sales) => {
  return sales.reduce((acc, sale) => {
    const date = new Date(sale.date_and_time).toDateString();
    if (!acc[date]) {
      acc[date] = [];
    }
    acc[date].push(sale);
    return acc;
  }, {});
};

export default Table;
