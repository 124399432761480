import React, { useEffect, useState } from "react";
import "./dashboard.css";
import Sidebar from "../../components/Sidebar/Sidebar";
import Navbar from "../../components/Navbar/Navbar";
import { useDispatch, useSelector } from "react-redux";
import SuperAdmin from "../../components/DashBoardComponent/SuperAdmin";
import Sales from "../../components/DashBoardComponent/Sales";
import SalesLead from "../../components/DashBoardComponent/SalesLead";
import Operational from "../../components/DashBoardComponent/Operational";
import {
  userState,
  userSalesInfo,
  welcomeMessage,
} from "../../Redux/userSlice";
import apiClient from "../../utils/axiosConfig";
import Table from "../../components/Table/Table";
// import Cookies from "js-cookie";

const Dashboard = () => {
  // class App extends React.Component {
  //      this.state = {
  //         username: Cookies.get('username')
  //      }

  // useEffect(() => {
  //   const token = Cookies.get();
  //   // console.log(token);
  // }, []);

  const role = useSelector((state) => state.user.role);
  const usersData = useSelector((state) => state.user.salesInfo);

  const dispatch = useDispatch();

  const getSalesData = async () => {
    try {
      let response;
      if (
        role === "superadmin" ||
        role === "operations" ||
        role === "salesperson"
      ) {
        response = await apiClient.get("/api/dashboard");
      } else {
        response = await apiClient.get("/api/sales-lead/sales");
      }
      if (response.data) {
        if (
          role === "superadmin" ||
          role === "operations" ||
          role === "salesperson"
        ) {
          dispatch(welcomeMessage(response?.data?.message));
        } else {
          dispatch(welcomeMessage(response.data.salesLead?.username));
        }

        const sortedData = response?.data?.sales.slice().sort((a, b) => {
          const dateA = new Date(a?.date_and_time);
          const dateB = new Date(b?.date_and_time);
          return dateB - dateA;
        });
        dispatch(userSalesInfo(sortedData));
      }
    } catch (error) {
      console.error(
        "Error fetching sales data:",
        error.response ? error.response.data : error.message
      );
    }
  };

  useEffect(() => {
    if (usersData.length === 0) {
      getSalesData();
    }
    // eslint-disable-next-line
  }, [usersData.length]);

  // const handleRoleChange = (e) => {
  //   dispatch(userState(e.target.value));
  // };

  return (
    <>
      <Navbar varient={"all"} />
      <Sidebar />

      {/* <select name="" id="" onChange={handleRoleChange}>
        <option value="salesperson">salesperson</option>
        <option value="sales_lead">salelead</option>
        <option value="operations">operations</option>
        <option value="superadmin">superadmin</option>
      </select> */}
      <div className="dashboard-Outer-Container">
        {role === "superadmin" ? <h1>Super Admin Dashboard</h1> : null}
        {role === "salesperson" ? <h1> Sales Person Dashboard</h1> : null}
        {role === "sales_lead" ? <h1>Sales Lead Dashboard</h1> : null}
        {role === "operations" ? <h1> Operations Dashboard</h1> : null}
      </div>

      <div className="dashboard-Inner-Container">
        {role === "superadmin" && <SuperAdmin />}
        {role === "salesperson" && <Sales />}
        {role === "sales_lead" && <SalesLead />}
        {role === "operations" && <Operational />}
      </div>

      {/* <Table /> */}
    </>
  );
};

export default Dashboard;
