import React, { useEffect, useState } from "react";
import Cards from "../Cards/Cards";
import DoughnutChart from "../CustomChart/DoughnutChart";
import MultiaxisChart from "../CustomChart/Multiaxis";
import "./DashboardComponents.css";
import LineSvg from "../../assets/svgs/LineSvg";
import { useSelector, useDispatch } from "react-redux";
import apiClient from "../../utils/axiosConfig";
import { userSalesInfo } from "../../Redux/userSlice";
import SalesOverview from "../Sales/SalesOverview";
import Button from "../Button/Button";
import Table from "../Table/Table";
import ClipLoader from "react-spinners/ClipLoader";

const Sales = () => {
  // Redux setup
  const roleSelector = useSelector((state) => state.user.role);
  const reduxAllSalesInfo = useSelector((state) => state.user.salesInfo);
  const dispatch = useDispatch();
  const role = useSelector((state) => state.user.role);
  // Pagination state
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 5;
  const allSalesInfo = reduxAllSalesInfo;

  // Handle page change
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  // Fetch data from API and dispatch to Redux
  const getData = async () => {
    try {
      let response;
      if (
        role === "superadmin" ||
        role === "operations" ||
        role === "salesperson"
      ) {
        response = await apiClient.get("/api/dashboard");
      } else {
        response = await apiClient.get("/api/sales-lead/sales");
      }
      const sortedData = response?.data?.sales.slice().sort((a, b) => {
        const dateA = new Date(a?.date_and_time);
        const dateB = new Date(b?.date_and_time);
        return dateB - dateA;
      });
      // console.log("sortedData", sortedData);
      dispatch(userSalesInfo(sortedData));
    } catch (error) {
      console.log("Error fetching sales data from sales table: ", error);
    }
  };

  // Fetch data on initial load
  useEffect(() => {
    if (reduxAllSalesInfo === 0) {
      getData();
    }
  }, []);

  // Calculate today's sales
  const todaysDate = new Date().toDateString();
  const todaySalesArray = allSalesInfo?.filter((data) =>
    new Date(data.date_and_time).toDateString().includes(todaysDate)
  );
  const todaySalesCount = todaySalesArray?.length;
  const todayTotalAmount = todaySalesArray?.reduce((acc, curr) => {
    return acc + Number(curr.amount);
  }, 0);

  // Calculate weekly sales
  const groupByDate = (sales) => {
    return sales.reduce((acc, sale) => {
      const date = new Date(sale.date_and_time).toDateString();
      if (!acc[date]) {
        acc[date] = [];
      }
      acc[date].push(sale);
      return acc;
    }, {});
  };
  const today = new Date();
  const oneWeekAgo = new Date(today);
  oneWeekAgo.setDate(today.getDate() - 7);
  const weeklySales = allSalesInfo.filter((sale) => {
    const saleDate = new Date(sale.date_and_time);
    return saleDate >= oneWeekAgo && saleDate <= today;
  });
  const weeklySalesByDate = groupByDate(weeklySales);
  const weeklySalesCount = Object.values(weeklySalesByDate);
  const weeklyTotalAmount = weeklySalesCount.map((sale) => {
    return sale.reduce((total, item) => total + parseFloat(item.amount), 0);
  });
  const sumUpWeeklySales = weeklyTotalAmount.reduce((curr, acc) => {
    return curr + acc;
  }, 0);

  // Calculate monthly sales
  const firstDayOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);
  const lastDayOfMonth = new Date(today.getFullYear(), today.getMonth() + 1, 0);
  // console.log("firstDayOfMonth", firstDayOfMonth);
  // console.log("lastDayOfMonth", lastDayOfMonth);

  const monthlySales = allSalesInfo.filter((sale) => {
    const saleDate = new Date(sale.date_and_time);
    // console.log("saleDate", saleDate);

    // Normalize the date to exclude time
    const saleDateOnly = new Date(
      saleDate.getFullYear(),
      saleDate.getMonth(),
      saleDate.getDate()
    );
    const firstDayOnly = new Date(
      firstDayOfMonth.getFullYear(),
      firstDayOfMonth.getMonth(),
      firstDayOfMonth.getDate()
    );
    const lastDayOnly = new Date(
      lastDayOfMonth.getFullYear(),
      lastDayOfMonth.getMonth(),
      lastDayOfMonth.getDate()
    );

    return saleDateOnly >= firstDayOnly && saleDateOnly <= lastDayOnly;
  });

  const totalMonthlySales = monthlySales.reduce(
    (total, sale) => total + parseFloat(sale.amount),
    0
  );
  // console.log("totalMonthlySales", totalMonthlySales);

  // Calculate yearly sales
  const currentYear = new Date().getFullYear();
  const yearlySales = allSalesInfo.filter(
    (item) => new Date(item.date_and_time).getFullYear() === currentYear
  );
  const totalYearlySales = yearlySales.reduce(
    (acc, curr) => acc + Number(curr.amount),
    0
  );
  const totalYearlySalesFormatted = totalYearlySales.toFixed(2);

  // Sales card data
  const SalesCardData = [
    {
      cardsTitle: "Today’s Revenue",
      subTitle: `₹${todayTotalAmount || "N/A"} `,
      icon: <LineSvg stroke={"#FFC1C1"} />,
    },
    {
      cardsTitle: "Weekly Revenues",
      subTitle: `₹${sumUpWeeklySales || "N/A"}`,
      icon: <LineSvg stroke={"#c1e0ff"} />,
    },
    {
      cardsTitle: "Monthly Revenue",
      subTitle: `₹${totalMonthlySales || "N/A"}`,
      icon: <LineSvg stroke={"#feffc1"} />,
    },
    {
      cardsTitle: "Today’s Enrollement Count",
      subTitle: `${todaySalesCount || "N/A"}`,
      icon: <LineSvg stroke={"#fcc1ff"} />,
    },
  ];

  // Sort data by date
  const sortedData = allSalesInfo.slice().sort((a, b) => {
    const dateA = new Date(a?.date_and_time);
    const dateB = new Date(b?.date_and_time);
    return dateA - dateB;
  });
  if (!allSalesInfo)
    return (
      <div className="loader-container">
        <ClipLoader
          color={"#4b74ff"}
          size={150}
          aria-label="Loading Spinner"
          data-testid="loader"
        />
      </div>
    );

  return (
    <div>
      <div className="bottom-Cards">
        <div className="cards salescard">
          {SalesCardData?.map((card, index) => (
            <Cards key={index} index={index} card={card} varient={"sales"} />
          ))}
        </div>
        <div className="doughnut-chart">
          <DoughnutChart
            todaysalesData={todayTotalAmount}
            weeklySalesData={sumUpWeeklySales}
            monthlySalesData={totalMonthlySales}
            yearlySalesData={totalYearlySalesFormatted}
          />
        </div>
      </div>
      <div className="multiaxis-chart">
        <MultiaxisChart allSalesData={sortedData} />
      </div>
      {roleSelector !== "superadmin" && roleSelector !== "sales_lead" ? (
        // <div>
        //   <div>
        //     <SalesOverview
        //       data={allSalesInfo}
        //       currentPage={currentPage}
        //       itemsPerPage={itemsPerPage}
        //       varient={"salesperson"}
        //     />
        //   </div>
        //   <br />
        //   <div className="pagination-container">
        //     <div className="pagination">
        //       <Button
        //         onClick={() => handlePageChange(currentPage - 1)}
        //         disabled={currentPage === 1}
        //         text={"Prev"}
        //       />
        //       <span>{currentPage}</span>
        //       <Button
        //         onClick={() => handlePageChange(currentPage + 1)}
        //         disabled={currentPage * itemsPerPage >= allSalesInfo.length}
        //         text={"Next"}
        //       />
        //     </div>
        //   </div>
        // </div>
        <div>
          <Table />
        </div>
      ) : null}
    </div>
  );
};

export default Sales;
