import React, { useEffect, useState } from "react";
import "./SalesDataAdminstration.css";
import Button from "../../Button/Button";
import { useNavigate } from "react-router-dom";
import apiClient from "../../../utils/axiosConfig";
import { useDispatch, useSelector } from "react-redux";
import { userSalesInfo } from "../../../Redux/userSlice";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ClipLoader from "react-spinners/ClipLoader";

const SalesDataAdminstration = ({ setAddBtn, addBtn }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  // State to manage the current page in pagination
  const [currentPage, setCurrentPage] = useState(1);
  const [getSalesData, setGetSalesData] = useState([]);
  const [loading, setLoading] = useState(true);

  const itemsPerPage = 25; // Number of items per page
  const startIndex = (currentPage - 1) * itemsPerPage; // Start index for pagination
  const endIndex = startIndex + itemsPerPage; // End index for pagination

  const salesInfo = useSelector((state) => state.user.salesInfo);
  // console.log("salesInfo", salesInfo);
  // console.log("getSalesData", getSalesData);

  // Function to handle page change in pagination
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  // Function to handle edit button click
  const handleEdit = (id) => {
    const searchParams = new URLSearchParams();
    searchParams.set("id", id);
    navigate(`/sales-app/sales?${searchParams.toString()}`);
    setAddBtn({ ...addBtn, sales: true });
  };

  // Function to handle delete button click
  const handleDelete = async (id) => {
    try {
      const response = await apiClient.delete(`/api/sale/delete/${id}`);
      if (response.data) {
        toast.success("Sale deleted successfully");
        fetchSalesData(); // Fetch the updated sales data after deletion
      }
    } catch (error) {
      console.error(
        "Error deleting sale:",
        error.response ? error.response.data : error.message
      );
    }
  };

  // Function to fetch sales data
  // const fetchSalesData = async () => {
  //   try {
  //     const response = await apiClient.get("/api/dashboard");
  //     setLoading(false);

  //     if (response.data) {
  //       const sortedData = response.data.sales.slice().sort((a, b) => {
  //         const dateA = new Date(a.date_and_time);
  //         const dateB = new Date(b.date_and_time);
  //         return dateB - dateA;
  //       });
  //       console.log("sortedData", sortedData);

  //       if (!salesInfo.length) {
  //         dispatch(userSalesInfo(sortedData));
  //       }
  //       setGetSalesData(sortedData);
  //     }
  //   } catch (error) {
  //     console.error(
  //       "Error fetching sales data:",
  //       error.response ? error.response.data : error.message
  //     );
  //     setLoading(false);
  //   }
  // };

  const fetchSalesData = async () => {
    try {
      const response = await apiClient.get("/api/dashboard");
      setLoading(false);

      if (response.data) {
        const sortedData = response.data.sales.slice().sort((a, b) => {
          const dateA = new Date(a.date_and_time);
          const dateB = new Date(b.date_and_time);
          return dateB - dateA;
        });
        // console.log("sortedData", sortedData);

        // Update Redux store with sorted data
        dispatch(userSalesInfo(sortedData));
        // Update local state with sorted data
        setGetSalesData(sortedData);
      }
    } catch (error) {
      console.error(
        "Error fetching sales data:",
        error.response ? error.response.data : error.message
      );
      setLoading(false);
    }
  };

  // Fetch sales data on component mount or if salesInfo is empty
  useEffect(() => {
    if (!salesInfo.length) {
      fetchSalesData();
    } else {
      setLoading(false);
    }
  }, [salesInfo]);

  // Slicing the sales data according to the current page
  const updateSalesDatas =
    salesInfo.length > 0
      ? salesInfo.slice(startIndex, endIndex)
      : getSalesData.slice(startIndex, endIndex);

  // console.log("updateSalesDatas", updateSalesDatas);

  if (loading) {
    return (
      <div className="sales-data">
        <div className="attendance-overview">
          <div className="loader-container">
            <ClipLoader
              color={"#4b74ff"}
              size={150}
              aria-label="Loading Spinner"
              data-testid="loader"
            />
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="sales-data">
      <div className="attendance-overview">
        <div className="header">
          <h2>Enrollment Data</h2>
        </div>
        <table className="table">
          <thead>
            <tr className="table-header">
              <th>#</th>
              <th>Order Id</th>
              <th>Date And Time</th>
              <th>Amount</th>
              <th>Customer Name</th>
              <th>Internship</th>
              <th>Approval Status</th>
              <th>Email</th>
              <th>Edit</th>
              <th>Delete</th>
            </tr>
          </thead>
          <tbody>
            {updateSalesDatas?.map((row, index) => (
              <tr className="table-row" key={row.id}>
                <td className="emp">{startIndex + index + 1}</td>
                <td className="emp">{row.order_id}</td>
                <td className="opacity-low">
                  {new Date(row.date_and_time).toDateString()}
                </td>
                <td className="opacity-low">₹{row.amount}</td>
                <td className="opacity-low">{row.customer_name}</td>
                <td className="opacity-low">{row.internship}</td>
                <td className={`status ${row.approval_status.toLowerCase()}`}>
                  {row.approval_status}
                </td>
                <td className="opacity-low">{row.customer_email}</td>
                <td>
                  <Button
                    text={"Edit"}
                    size="medium"
                    onClick={() => {
                      handleEdit(row.id);
                    }}
                  />
                </td>
                <td>
                  <button
                    className="delete-btn"
                    onClick={() => handleDelete(row.id)}
                  >
                    Delete
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div className="pagination-container" style={{ paddingTop: "20px" }}>
        <div className="pagination">
          <Button
            onClick={() => handlePageChange(currentPage - 1)}
            disabled={currentPage === 1}
            text={"Prev"}
          />
          <span>{currentPage}</span>
          <Button
            onClick={() => handlePageChange(currentPage + 1)}
            disabled={currentPage >= Math.ceil(salesInfo.length / itemsPerPage)}
            text={"Next"}
          />
        </div>
      </div>
      <ToastContainer position="top-right" autoClose={3000} />
    </div>
  );
};

export default SalesDataAdminstration;
