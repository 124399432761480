import React, { useEffect, useState } from "react";
import Sales from "./Sales";
import SalesOverview from "../Sales/SalesOverview";
import "./DashboardComponents.css";
// import { salesData } from "../../utils/data";
import { useDispatch, useSelector } from "react-redux";
import apiClient from "../../utils/axiosConfig";
import { userSalesInfo } from "../../Redux/userSlice";
import Button from "../Button/Button";
import Table from "../Table/Table";

const SalesLead = () => {
  const allSalesData = useSelector((state) => state.user.salesInfo);

  // console.log("all sales data", allSalesData.length);
  const role = useSelector((state) => state.user.role);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 25;

  // Handle pagination change
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const dispatch = useDispatch();
  const getData = async () => {
    try {
      let response;
      if (
        role === "superadmin" ||
        role === "operations" ||
        role === "salesperson"
      ) {
        response = await apiClient.get("/api/dashboard");
      } else {
        response = await apiClient.get("/api/sales-lead/sales");
      }

      if (!response.data || !response.data.sales) {
        throw new Error("Invalid response format");
      }

      const sortedData = response.data.sales.slice().sort((a, b) => {
        const dateA = new Date(a?.date_and_time);
        const dateB = new Date(b?.date_and_time);
        return dateB - dateA;
      });

      dispatch(userSalesInfo(sortedData));
    } catch (error) {
      console.error("Error fetching sales data:", error.message);
      // Optionally, you can dispatch an action to update the state with the error
      // dispatch(setError(error.message));
    }
  };

  useEffect(() => {
    if (allSalesData.length === 0) {
      getData();
    }
  }, [allSalesData.length]);

  // console.log(allSalesData);

  // console.log(sortedData);

  // console.log(reduxSalesData);

  return (
    <>
      <Sales />

      <div>
        {/* <SalesOverview
          data={allSalesData}
          currentPage={currentPage}
          itemsPerPage={itemsPerPage}
        /> */}

        <Table />
      </div>
      {/* <div className="pagination-container">
        <div className="pagination">
          <Button
            onClick={() => handlePageChange(currentPage - 1)}
            disabled={currentPage === 1}
            text={"Prev"}
          />

          <span>{currentPage}</span>

          <Button
            onClick={() => handlePageChange(currentPage + 1)}
            disabled={currentPage * itemsPerPage >= allSalesData.length}
            text={"Next"}
          />
        </div>
      </div> */}
    </>
  );
};

export default SalesLead;

// attendence  -> admin
