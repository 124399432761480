import React, { useEffect, useState } from "react";
import SearchIcon from "../../../assets/SearchIcon.png";
import "./SalesMainContent.css";
import Button from "../../Button/Button";
import UsersTable from "../UserAdministrationTable/UsersTable";
import apiClient from "../../../utils/axiosConfig";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useDispatch, useSelector } from "react-redux";
import { activeSaleSideNav, allUsersInfo } from "../../../Redux/userSlice";
import ClipLoader from "react-spinners/ClipLoader";
const UserMainContent = ({ setAddBtn, addBtn }) => {
  const [userData, setUserData] = useState([]);
  const [searchedData, setSearchedData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 25;
  const dispatchActiveSlide = useDispatch();
  const allUsersInfoDispatch = useDispatch();
  const allUsersInfoData = useSelector((state) => state.user.allUsersInfo);

  const fetchUserData = async () => {
    try {
      const response = await apiClient.get("/api/user/users");
      if (response.data) {
        setUserData(response.data.users);
        setSearchedData(response.data.users); // Initialize searchedData with userData
        // Update Redux store with fetched data
        allUsersInfoDispatch(allUsersInfo(response.data.users));
      }
    } catch (e) {
      console.error(
        "Error fetching user data:",
        e.response ? e.response.data : e.message
      );
    } finally {
      setLoading(false); // Set loading to false once data is fetched
    }
  };

  useEffect(() => {
    if (!allUsersInfoData.length) {
      fetchUserData();
    } else {
      setUserData(allUsersInfoData);
      setSearchedData(allUsersInfoData);
      setLoading(false);
    }
  }, [allUsersInfoData]);

  const filterData = (e) => {
    const filteredData = userData.filter((data) => {
      return data.username.toLowerCase().includes(e.target.value.toLowerCase());
    });
    setSearchedData(filteredData);
  };

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  if (loading) {
    return (
      <div className="loader-container">
        <ClipLoader
          color={"#4b74ff"}
          size={150}
          aria-label="Loading Spinner"
          data-testid="loader"
        />
      </div>
    );
  }

  const handleDeleteUser = async (id) => {
    try {
      const response = await apiClient.delete(`/api/user/delete/${id}`);
      if (response.data) {
        toast("User deleted successfully");
        // window.location.reload();
        dispatchActiveSlide(activeSaleSideNav("users"));
        fetchUserData();
      }
    } catch (error) {
      console.log(error);
    }
  };

  // console.log(userData);

  return (
    <div className="sales-main-container">
      <div>
        <div className="sales-main-nav">
          <p>Manage User Information</p>
          <div className="sales-main-input">
            <img src={SearchIcon} alt="Search Icon" />
            <input
              type="search"
              placeholder="Search"
              onChange={(e) => filterData(e)}
            />
          </div>
        </div>
        <div>
          <UsersTable
            setAddBtn={setAddBtn}
            addBtn={addBtn}
            userData={searchedData}
            currentPage={currentPage}
            itemsPerPage={itemsPerPage}
            handleDeleteUser={handleDeleteUser}
          />

          <div className="sales-main-pagination">
            <Button
              text={"Prev"}
              size={"medium"}
              onClick={() => handlePageChange(currentPage - 1)}
              disabled={currentPage === 1}
            />
            <span>{currentPage}</span>
            <Button
              text={"Next"}
              size={"medium"}
              onClick={() => handlePageChange(currentPage + 1)}
              disabled={currentPage * itemsPerPage >= searchedData.length}
            />
          </div>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};

export default UserMainContent;
